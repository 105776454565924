import {createApp} from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import {loadFonts} from './plugins/webfontloader'
import './assets/sass/style.scss';
import { createI18n } from 'vue-i18n'
import FlagIcon from 'vue-flag-icon'
loadFonts()
const messages = {
    us: {
        message: {
            //start page
            btnAbout: 'about',
            btnProjects: 'projects',
            btnServices: 'services',

            //page about me
            aboutMeTitle: 'Hi, my name is Regina',
            aboutMeText: 'I am a graphic designer & art director from Moscow, <br>capturing the meanings of brands through elaborate and aesthetic identity',
            aboutMeSkills: 'PROGRAMS & SKILLS',
            aboutContacts: 'CONTACTS',
            typography: 'Typography',

            // page my projects
            myProjects: 'MY PROJECTS',
            myProjectsInfo: '* More project details can be found on my $Behance account and $Instagram',

            // page services
            servicesTitle: 'SERVICES. BRAND IDENTITY',
            services1Title: 'WORK STAGES',
            services1row1: 'Working conditions negotiation',
            services1row2: 'Prepayment of 50%',
            services1row3: 'Video call and briefing filling',
            services1row4: 'Brief approval',
            services1row5: 'Creating a design',
            services1row6: 'Project approval',
            services1row7: 'Payment of the remaining part',
            services1row8: 'Sending ready files',

            services2Title: 'BRANDING ELEMENTS',
            services2row1: 'Logotype and its variations',
            services2row2: 'Brand colours',
            services2row3: 'Pattern and graphic elements',
            services2row4: 'Typography',
            services2row5: 'Social Media Design',
            services2row6: 'Print Designs',

            services3Title: 'PACKAGES OF SERVICES',
            services3row1: 'Basic brand identity',
            services3row2: 'Full brand identity',
            services3row3: 'Brand identity for social media',

            services4Title: 'EMPLOYMENT CONDITIONS',
            services4row1: 'The service is booked on 50% prepayment,<br> the prepayment is non-refundable',
            services4row2: 'Depending on the number of carriers<br>' +
                'and project filling the price may vary,<br>' +
                'more details will be specified when<br>' +
                'discussing the details.',
            services4row3: 'Working hours: Mon-Fri 9:00-18:00',

            //page packages
            packagesTitle: 'PACKAGES.  BRAND IDENTITY',
            packages1Title: 'BASIC BRAND IDENTITY',
            packages1row1: 'Creating 1-2 versions of logotype<br>' +
                'Selection of brand colour palette<br>' +
                'Creation of visual concept<br>' +
                '(graphic elements, pattern)<br' +
                '>Up to 3 branded carriers<br>' +
                '(additional carriers can be added)<br>' +
                '2 stages of free editing<br>' +
                'Final presentation<br>' +
                'Print and web files',
            packages2Title: 'EXTENDED BRAND IDENTITY',
            packages2Additional: 'printing support*',
            packages2row1: 'Creating 1-2 versions of logotype <br>' +
                'Selection of brand colour palette <br>' +
                'Creation of visual concept <br>' +
                '(graphic elements, pattern)<br>' +
                'Up to 7 branded carriers<br>' +
                '(additional carriers can be added)<br>' +
                '2 stages of free editing<br>' +
                'Final presentation<br>' +
                'Print and web files',
            packages3Title: 'SOCIAL MEDIA BRAND IDENTITY',
            packages3row1: 'Creating a concept and profile visuals<br>' +
                'Design of avatar, highlights (up to 10 pc)<br>' +
                'Creating templates for posts and stories<br>' +
                'based on the selected concept<br>' +
                'Creating a feed of 12 posts <br>' +
                'based on the selected concept<br>' +
                'Content guidelines for photographers<br>' +
                'Guided instructions for editing <br>' +
                'templates maintaining style, etc.',

            // request dialog
            dialogTitle: 'Order service',
            dialogLabelPhone: 'Phone',
            dialogLabelEmail: 'Email',
            dialogLabelText: 'Explain your request',

            // other
            submitRequest: 'Submit a request',
        }
    },
    ru: {
        message: {
            //start page
            btnAbout: 'обо мне',
            btnProjects: 'портфолио',
            btnServices: 'услуги',

            //page about me
            aboutMeTitle: 'Привет, меня зовут Регина',
            aboutMeText: 'Я - графический дизайнер & арт-директор из Москвы,<br/>' +
                'отражаю смыслы брендов через продуманную<br>' +
                'и эстетичную айдентику, вдохновляю коллег',
            aboutMeSkills: 'ПРОГРАММЫ И НАВЫКИ',
            aboutContacts: 'МОИ КОНТАКТЫ',
            typography: 'Типографика',

            // page my projects
            myProjects: 'МОИ РАБОТЫ',
            myProjectsInfo: '* Подробные детали проектов и больше работ можно посмотреть на моей странице $Behance',

            // page services
            servicesTitle: 'УСЛУГИ. РАЗРАБОТКА ФИРМЕННОГО СТИЛЯ',
            services1Title: 'ЭТАПЫ РАБОТЫ',
            services1row1: 'Согласование условий работы',
            services1row2: 'Внесение предоплаты',
            services1row3: 'Видеосозвон и заполнение брифа',
            services1row4: 'Согласование брифа ',
            services1row5: 'Разработка дизайна',
            services1row6: 'Согласование проекта',
            services1row7: 'Внесение полной оплаты',
            services1row8: 'Отправка готовых файлов',

            services2Title: 'ЭЛЕМЕНТЫ АЙДЕНТИКИ',
            services2row1: 'Логотип и его вариации',
            services2row2: 'Фирменные цвета',
            services2row3: 'Паттерн и графические приемы',
            services2row4: 'Типографика',
            services2row5: 'Оформление соцсетей',
            services2row6: 'Макеты для печати',

            services3Title: 'ПАКЕТЫ УСЛУГ',
            services3row1: 'Стандартный фирменный стиль',
            services3row2: 'Расширенный фирменный стиль',
            services3row3: 'Фирменный стиль для соцсетей',

            services4Title: 'УСЛОВИЯ РАБОТЫ',
            services4row1: 'Услуга бронируется по 50% предоплате,<br>' +
                'предоплата не возвращается',
            services4row2: 'В зависимости от количества носителей<br>' +
                'и наполнения проекта цена может <br>' +
                'меняться, подробнее уточняется <br>' +
                'при обсуждении деталей',
            services4row3: 'Режим работы: Пн-Пт 9:00-18:00',

            //page packages
            packagesTitle: 'ПАКЕТЫ. РАЗРАБОТКА ФИРМЕННОГО СТИЛЯ',
            packages1Title: 'ПАКЕТ “СТАНДАРТНЫЙ',
            packages1row1: 'Разработка 1-2 вариантов логотипа<br>' +
                'Подбор цветовой палитры<br>' +
                'Подбор фирменных цветов<br>' +
                'Создание визуальной концепции<br>' +
                '(графические элементы, паттерн)<br>' +
                'До 3 фирменных носителей<br>' +
                '(могут быть добавлены дополнительные)<br>' +
                '2 этапа бесплатных правок <br>' +
                'Финальная презентация<br>' +
                'Файлы для печати и web-устройств',
            packages2Title: 'ПАКЕТ “РАСШИРЕННЫЙ',
            packages2Additional: 'Cопровождение печати*',
            packages2row1: 'Разработка 1-2 вариантов логотипа<br>' +
                'Подбор цветовой палитры<br>' +
                'Подбор фирменных цветов<br>' +
                'Создание визуальной концепции<br>' +
                '(графические элементы, паттерн)<br>' +
                'До 7 фирменных носителей<br>' +
                '(могут быть добавлены дополнительные)<br>' +
                '2 этапа бесплатных правок<br>' +
                'Финальная презентация<br>' +
                'Файлы для печати и web-устройств',
            packages3Title: 'ПАКЕТ ДЛЯ СОЦСЕТЕЙ',
            packages3row1: 'Создание концепции и визуала профиля<br>' +
                'Создание шаблонов для постов и сторис<br>' +
                'на основе выбранной концепции<br>' +
                'Формирование ленты на 12 постов <br>' +
                'на основе созданной концепции<br>' +
                'Рекомендации фотографу по контенту<br>' +
                'Инструкция по редактированию <br>' +
                'шаблонов, поддержанию стиля и т.д.',

            // request dialog
            dialogTitle: 'Приобрести услугу',
            dialogLabelPhone: 'Номер телефона',
            dialogLabelEmail: 'Электронная почта',
            dialogLabelText: 'Опишите ваш запрос',

            // other
            submitRequest: 'Оставить заявку',
        }
    }
}

const i18n = createI18n({
    locale: 'ru', // set locale
    fallbackLocale: 'ru', // set fallback locale
    messages,
})

createApp(App)
    .use(vuetify)
    .use(FlagIcon)
    .use(i18n)
    .mount('#app');

