import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyAdtxCXhL5r5XfcQ3u8ExiwzeF7mOhEZMQ",
    authDomain: "rdi-studios.firebaseapp.com",
    projectId: "rdi-studios",
    storageBucket: "rdi-studios.appspot.com",
    messagingSenderId: "587555223849",
    appId: "1:587555223849:web:b0d8e90b3793abe2361f38",
    measurementId: "G-FPHTER500S"
};


let app = initializeApp(firebaseConfig);

const db = getFirestore(app);

export { db };